/*
for css vars only.
these values are automatically known in all stylesheets.
the :root statement itself is only included in the common stylesheet.
this file is not processed by postcss when imported into the postcss-custom-properties plugin,
so only write standard css!

NOTE: for old browsers, will need to restart watcher after changing a variable
*/

:root {
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #f1f1f1; /*#e9e9e9;*/

  --fc-button-text-color: #212e54; /*#fff;*/
  --fc-button-bg-color: #fff;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #f2f5fe;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #212e54;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: #f2f5fe; /* rgba(188, 232, 241, 0.3); */
  --fc-today-bg-color: #f2f5fe; /* rgba(255, 220, 40, 0.15); */
  --fc-now-indicator-color: red;
}
.audit-event {
  background-color: transparent !important;
}
.audit-event:focus::after {
  background-color: transparent !important;
}
.fc-col-header-cell-cushion {
  color: var(--fc-button-text-color) !important;
  cursor: default;
}
.fc-day:not(.fc-day-other) > div > div > a {
  /* Your styles here */
  color: var(--fc-button-text-color) !important;
}

.fc-button-active {
  color: #fff !important;
}
.fc-button:active {
  color: #fff !important;
}
.fc-day-today {
  box-shadow: inset 0px 2px 0px 0px #151e27 !important;
}
.fc-day-today .fc-daygrid-week-number {
  margin-top: 2px;
}

.fc-daygrid-day-frame {
  cursor: pointer !important;
}
.fc-daygrid-week-number {
  font-size: 12px;
  color: var(--fc-button-text-color) !important;
  background-color: #f9f9f9 !important;
  border-bottom: 1px solid var(--fc-border-color) !important;
  border-right: 1px solid var(--fc-border-color) !important;
}
.fc-multimonth-daygrid-table .fc-daygrid-week-number {
  color: red;
  font-size: 0.8em !important;
}

.fc-day-sat.fc-day-today {
  background-color: var(--fc-today-bg-color) !important;
}
.fc-day-sun.fc-day-today {
  background-color: var(--fc-today-bg-color) !important;
}
.fc-day-sun .audit-multiday {
  border-right: none !important;
}
.fc-day-mon .audit-multiday {
  border-left: none !important;
}
.fc-day-mon .audit-multiday span {
  opacity: 0;
}
.fc-day-sat {
  background-color: #f9f9f9 !important;
}
.fc-day-sun {
  background-color: #f9f9f9 !important;
}

.fc-event:not(.fc-event-start):not(.fc-event-end) > .fc-event-main > div {
  /* Your styles here */
  border-left: none !important;
  border-right: none !important;
}

/* Applies to .fc-event elements that have .fc-event-start but NOT .fc-event-end */
.fc-event.fc-event-start:not(.fc-event-end) > .fc-event-main > div {
  /* Your styles here */
  border-right: none !important;
}
/* Applies to .fc-event elements that have .fc-event-end but NOT .fc-event-start */
.fc-event.fc-event-end:not(.fc-event-start) > .fc-event-main > div {
  /* Your styles here */
  border-left: none !important;
}

.fc-toolbar-title {
  text-transform: capitalize;
}
