/* @import "~antd/dist/antd.css"; */
/* @import "~antd/dist/antd.css"; */

.App {
  height: inherit;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* SIDE NAV */
.sidenav_logo {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  height: 32px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 16px;
}

.avatar-uploader .ant-upload {
  width: 100% !important;
}

.avatar-uploader .ant-upload-list-picture-card .ant-upload-list-item {
  width: 100%;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 1;
}

/* HOVER THUMBNAIL */
.thumbnail-issues {
  position: relative;
  transition: all 0.375s;
}

.thumbnail-overlay {
  transition: background-color 0.375s, visibility 0.175s;
  visibility: hidden;
  top: 0;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.thumbnail-issues:hover .thumbnail-overlay {
  cursor: pointer;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.2);
}

.thumbnail-issues-image {
  background-size: cover !important;
  border-radius: 8px !important;
}

/* LOGIN FORM */
.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-form {
  min-width: 300px;
  max-width: 400px;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}

.ant-table {
  overflow-x: scroll;
}

.ant-table td {
  white-space: nowrap;
}

.ant-input-number {
  width: 100% !important;
}

.ant-fullcalendar-content {
  height: 65px;
}
.ant-fullcalendar-content ul {
  list-style: none;
  padding: 0;
}
.ant-list-item {
  position: relative;
}
.audit-calendar .ant-picker-calendar-date-value {
  padding: 8px;
  padding-top: 4px;
}
.audit-calendar .ant-picker-cell-inner {
  padding: 0 !important;
}
.audit-calendar .ant-badge-status-text {
  font-size: 12px !important;
  line-height: 1 !important;
}

/* Datepicker */
.ant-picker-cell-today {
}
/* .ant-list-item-meta-title {
    white-space: nowrap;
} */
@media (max-width: 600px) {
  .ant-list-item-action {
    position: absolute;
    margin-left: 0px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border-radius: 5px;
  }
}
#HW_badge_cont {
  position: absolute !important;
  top: 0;
  z-index: 100;
  padding: 32px;
  right: 0;
}

#HW_badge {
  left: 55px !important;
  top: 13px !important;
  background-color: #d50000 !important;
}

.ant-layout-content {
  min-height: auto !important;
}

.ant-picker-calendar-date-content .events {
  list-style: none;
  padding: 0;
}

.ant-table-cell-row-hover {
  /* background-color: transparent !important; */
}

/* Certification Wizard */
.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 24px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.headwayapp-updates .HW_softHidden {
  display: none !important;
}

.custom-audit-file-upload .ant-upload-wrapper {
  display: flex;
  flex-direction: row-reverse;
}
.custom-audit-file-upload .ant-upload-list {
  display: flex;
}

.custom-audit-task-list-item .ant-list-item-action {
  display: flex;
}
.custom-audit-task-list-item .ant-list-item-meta {
  align-items: center !important;
}
.custom-audit-task-list-item .ant-list-item-meta-title {
  margin: 0 !important;
}

.custom-audit-task-list-item-checkbox .ant-checkbox-inner {
  width: 28px !important;
  height: 28px !important;
}
.custom-audit-task-list-item-checkbox .ant-checkbox-inner::after {
  top: 48% !important;
  inset-inline-start: 27.5% !important;
  width: 8px !important;
  height: 13px !important;
}

/* Schedule Modal */
.schedule-modal {
  transition: width 0.3s;
}
.schedule-modal .ant-modal-content {
  padding: 0 !important;
}

.nested-table .ant-table {
  /* border-radius: 0px !important; */
  margin: 0px !important;
  border-end-end-radius: 8px !important;
  border-end-start-radius: 8px !important;
  /* border-bottom-left-radius: 8px !important; */
}

.dept-table .ant-table-expanded-row > .ant-table-cell {
  /* padding: 0px !important;  */
  background-color: #f1f1f1 !important;
}
.picture-wall-card-issue {
  /* transition: all 0.375s; */
}
.picture-wall-card-issue:hover::before {
  transition: all 0.375s;
  content: '';
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust opacity */
  pointer-events: none; /* So the overlay doesn't block clicks */
  z-index: 1;
}

.picture-wall-card-issue .ant-btn {
  display: none;
}
.picture-wall-card-issue:hover .ant-btn {
  display: block;

  /* top: 50%;
    left: 50%; */
}


.drawer-offer > .ant-drawer {
  z-index: 10 !important;
}