.report-page {
  padding: 48px;
  background-color: #fff;
  min-height: 100vh;
}

@media print {
  .page-break {
    page-break-before: always;
  }
  .report-page {
    padding: 0px;
  }
  .print-hide {
    display: none;
  }
}
