.triangle-pulsing-red {
  fill: red;
  fill-rule: evenodd;
  stroke-width: 2px;
  animation: pulsingRed 4s infinite;
}
@keyframes pulsingRed {
  0% {
    fill: red;
    stroke: red;
  }
  49% {
    fill: red;
    stroke: red;
  }
  50% {
    fill: #fef7ff;
    stroke: black;
  }
  99% {
    fill: #fef7ff;
    stroke: black;
  }
}

.triangle-pulsing-green {
  fill: #43f59f;
  fill-rule: evenodd;
  stroke-width: 2px;
  animation: pulsingGreen 4s infinite;
}
@keyframes pulsingGreen {
  0% {
    fill: #43f59f;
    stroke: #43f59f;
  }
  49% {
    fill: #43f59f;
    stroke: #43f59f;
  }
  50% {
    fill: #fef7ff;
    stroke: black;
  }
  99% {
    fill: #fef7ff;
    stroke: black;
  }
}

.triangle-solid-blue {
  fill: blue;
  fill-rule: evenodd;
  stroke-width: 2px;
}
.triangle-solid-green {
  fill: #43f59f;
  fill-rule: evenodd;
  stroke-width: 2px;
}
.triangle-solid-red {
  fill: red;
  fill-rule: evenodd;
  stroke-width: 2px;
}
.triangle-solid-yellow {
  fill: #fada0c;
  fill-rule: evenodd;
  stroke-width: 2px;
}
.triangle-solid-grey {
  fill: grey;
  fill-rule: evenodd;
  stroke-width: 2px;
}
.triangle-solid-white {
  fill: #fef7ff;
  fill-rule: evenodd;
  stroke: black;
  stroke-width: 2px;
}
